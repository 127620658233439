import { mobilePlanAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export async function useBouyguesAccount(email: string) {
  const {
    data: bouyguesAccount,
    pending,
    error,
    refresh,
  } = useHttpFetch(mobilePlanAPI.checkMobilePlanCustomerAccountStatus, {
    body: {
      partner: 'BOUYGUES_TELECOM',
      email,
    },
  })

  return {
    bouyguesAccount,
    pending,
    error,
    refresh,
  }
}
