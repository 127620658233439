<template>
  <Loading
    v-if="isLoading"
    :pending="pending"
    @animation-ended="() => (isLoading = false)"
  />

  <BouyguesError v-if="!isLoading && error" @try-again="refresh" />

  <BouyguesAccount
    v-if="!isLoading && bouyguesAccount"
    :is-account-existing="bouyguesAccount.existing"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import BouyguesError from '../../components/BouyguesError/BouyguesError.vue'
import { useUser } from '../../composables/useUser'

import BouyguesAccount from './components/BouyguesAccount/BouyguesAccount.vue'
import Loading from './components/Loading/Loading.vue'
import { useBouyguesAccount } from './composables/useBouyguesAccount'

const user = useUser()

const { bouyguesAccount, pending, error, refresh } = await useBouyguesAccount(
  user.email,
)

const isLoading = ref(true)
</script>
