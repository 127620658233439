<template>
  <RevForm
    :initial-values="initialValues"
    :validate="validate"
    @submit="handleSubmit"
  >
    <template #default="{ values, errors }">
      <div class="space-y-24">
        <div class="space-y-16">
          <h3 class="heading-3">{{ i18n(translations.title) }}</h3>

          <RevCard class="space-y-24 p-24">
            <RevInputText
              id="signup-form-email"
              v-model="values.email"
              :description="i18n(translations.emailInputDescription)"
              :error="errors.email || apiErrors.email"
              :icon="IconMail"
              :label="i18n(translations.emailInputLabel)"
            />
            <RevLink
              class="block"
              :to="authorizeUrl.href"
              :tracking="{
                zone: 'bouygues_sign_up',
                name: 'already_client',
                page_type: route.name as string,
              }"
            >
              {{ i18n(translations.existingAccount) }}
            </RevLink>
          </RevCard>
        </div>

        <div class="space-y-16">
          <h3 class="heading-3">{{ i18n(translations.personalInfoTitle) }}</h3>

          <RevCard class="space-y-16 p-24">
            <RevInputText
              id="signup-form-firstname"
              v-model="values.firstName"
              :error="errors.firstName"
              :label="i18n(translations.firstNameInputLabel)"
            />
            <RevInputText
              id="signup-form-lastname"
              v-model="values.lastName"
              :error="errors.lastName"
              :label="i18n(translations.lastNameInputLabel)"
            />
            <RevInputPhone
              id="signup-form-phone"
              v-model="values.phoneNumber"
              :clear-button-aria-label="
                i18n(translations.phoneNumberInputClear)
              "
              :default-country="market.countryCode"
              description=""
              :error="errors.phoneNumber"
              :label="i18n(translations.phoneNumberInputLabel)"
              :translate="i18n.country"
            />
          </RevCard>
        </div>

        <div class="space-y-16">
          <header>
            <h3 class="heading-3">{{ i18n(translations.identityTitle) }}</h3>
            <p>{{ i18n(translations.identitySubtitle) }}</p>
          </header>

          <RevCard class="space-y-32 p-24">
            <div class="space-y-16">
              <div class="grid grid-cols-2">
                <RevRadio
                  id="male"
                  v-model="values.gender"
                  name="gender"
                  value="MALE"
                >
                  <template #label>
                    {{ i18n(translations.genderInputLabelMale) }}
                  </template>
                </RevRadio>

                <RevRadio
                  id="female"
                  v-model="values.gender"
                  name="gender"
                  value="FEMALE"
                >
                  <template #label>
                    {{ i18n(translations.genderInputLabelFemale) }}
                  </template>
                </RevRadio>
              </div>

              <RevInputDate
                id="signup-form-birthdate"
                v-model="values.birthdate"
                :error="errors.birthdate || apiErrors.birthdate"
                :label="i18n(translations.birthdateInputLabel)"
              />

              <RevInputSelect
                id="signup-form-birth-department"
                v-model="values.birthDepartment"
                :error="errors.birthDepartment"
                :label="i18n(translations.birthDepartmentInputLabel)"
                :options="birthDepartmentOptions"
              />
            </div>
            <RevButton
              :disabled="isLoading"
              full-width="always"
              :loading="isLoading"
              type="submit"
              variant="primary"
            >
              {{ i18n(translations.submit) }}
            </RevButton>
          </RevCard>
        </div>

        <FormattedMessage
          class="text-static-default-low caption block whitespace-pre-line"
          :definition="translations.legalTerms"
        >
          <template #privacyPolicy>
            <RevLink
              target="_blank"
              to="https://www.bouyguestelecom.fr/static/cms/tarifs/Politique_De_Confidentialite.pdf"
            >
              {{ i18n(translations.privacyPolicy) }}
            </RevLink>
          </template>
        </FormattedMessage>
      </div>
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { ref } from 'vue'

import { HttpApiError, mobilePlanAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import {
  RevForm,
  makeValidate,
  matchingRegExp,
  required,
} from '@ds/components/Form'
import { RevInputDate } from '@ds/components/InputDate'
import { RevInputPhone } from '@ds/components/InputPhone'
import { RevInputSelect } from '@ds/components/InputSelect'
import { RevInputText } from '@ds/components/InputText'
import { RevLink } from '@ds/components/Link'
import { RevRadio } from '@ds/components/Radio'
import { IconMail } from '@ds/icons/IconMail'

import { getAPIValidationError } from '~/scopes/checkout/utils/getAPIValidationError'
import { getFormattedPhoneNumber } from '~/scopes/checkout/utils/getFormattedPhoneNumber'
import { validateBirthdate } from '~/scopes/checkout/validators/validateBirthdate'
import { validateFrenchPhoneNumber } from '~/scopes/checkout/validators/validateFrenchPhoneNumber'

import { useBouyguesAuthorize } from '../../../../composables/useBouyguesAuthorize'

import { frenchDepartments } from './SignUpForm.constant'
import translations from './SignUpForm.translations'

const i18n = useI18n()
const route = useRoute()
const logger = useLogger()
const { market } = useMarketplace()
const { openErrorToast } = useTheToast()
const { authorizeUrl } = useBouyguesAuthorize()
const { trackFormSubmit } = useTracking()

const props = defineProps<{
  user: {
    email: string
    firstName: string
    lastName: string
    phone: { dial?: string; phone?: string }
  }
}>()

const birthDepartmentOptions = frenchDepartments.map(({ name, code }) => ({
  label: `${code} - ${name}`,
  value: code,
}))

const apiErrors = ref<{
  email?: string
  birthdate?: string
}>({})

type Values = {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  gender: 'MALE' | 'FEMALE'
  birthdate: string
  birthDepartment: string
}

const initialValues: Values = {
  email: props.user.email,
  firstName: props.user.firstName,
  lastName: props.user.lastName,
  phoneNumber:
    getFormattedPhoneNumber(
      market.countryCode,
      props.user.phone.dial,
      props.user.phone.phone,
    ) || '',
  gender: 'MALE',
  birthdate: '',
  birthDepartment: '',
}

const emailRegex = /^[^\s@]+@[^\s@]+$/

const validate = makeValidate<Values>({
  email: [
    required(i18n(translations.emailInputErrorRequired)),
    matchingRegExp(emailRegex, i18n(translations.emailInputErrorInvalid)),
  ],
  firstName: required(i18n(translations.inputRequired)),
  lastName: required(i18n(translations.inputRequired)),
  phoneNumber: validateFrenchPhoneNumber(
    i18n(translations.inputRequired),
    i18n(translations.phoneNumberInputErrorInvalid),
  ),
  birthdate: [
    required(i18n(translations.inputRequired)),
    validateBirthdate(i18n(translations.birthdateInputInvalidError)),
  ],
  birthDepartment: required(i18n(translations.inputRequired)),
})

const isLoading = ref(false)

const handleSubmit = async (values: Values) => {
  isLoading.value = true

  try {
    await $httpFetch(mobilePlanAPI.createCustomerAccount, {
      body: {
        ...values,
        partner: 'BOUYGUES_TELECOM',
      },
    })

    trackFormSubmit({
      zone: 'bouygues_sign_up',
      name: 'create_bouygues_account',
      page_type: route.name as string,
    })

    window.location.assign(authorizeUrl.href)
  } catch (err) {
    const httpApiError = err as HttpApiError

    if (httpApiError.status === 400) {
      apiErrors.value.birthdate = getAPIValidationError<Values>(
        httpApiError.errors,
        'birthdate',
        '/errors/ineligible-customer-age',
        i18n(translations.birthdateInputErrorUnder18),
      )

      const emailAlreadyUsedError = getAPIValidationError<Values>(
        httpApiError.errors,
        'email',
        '/errors/email-address-already-used',
        i18n(translations.emailInputErrorAlreadyUsed),
      )

      const emailWrongFormat = getAPIValidationError(
        httpApiError.errors,
        'body.email',
        '/errors/value_error',
        i18n(translations.emailInputErrorInvalid),
      )

      apiErrors.value.email = emailWrongFormat || emailAlreadyUsedError

      return
    }

    openErrorToast()
    logger.error(httpApiError.message, { error: httpApiError })
  } finally {
    isLoading.value = false
  }
}
</script>
